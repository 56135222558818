<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card height="60vh">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <DatePicker
                v-model="item.receive_time"
                :model-config="modelConfig"
                mode="dateTime"
                :minute-increment="30"
                :valid-hours="{ min: 8, max: 18 }"
                is24hr
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :value="inputValue"
                    :label="$t('container-box.receive-date')"
                    v-on="inputEvents"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>{{ $t('container-box.receive-date') }}
                    </template>
                  </v-text-field>
                </template>
              </DatePicker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="item.warehouse"
                :label="$t('warehouse.warehouse')"
                :items="warehouse_items"
                :item-text="item => $t(item.text)"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="allowSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { DatePicker } from "v-calendar";
import BaseDialog from "./BaseDialog";
export default {
  props: {
    warehouse_items: []
  },
  data: (vm) => ({
    topic: vm.$i18n.t("container-box.receive-date"),
    modelConfig: {
      type: "string",
      mask: "YYYY-MM-DD HH:mm", // Uses 'iso' if missing
    },
  }),
  computed: {
    allowSave() {
      return !(this.item.receive_time &&
      this.item.warehouse)
    },
  },
  components: {
    DatePicker,
  },
  mixins: [BaseDialog],
  methods: {

  },
};
</script>
