<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <ReceiveDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      :warehouse_items="warehouse_items"
      v-on:onClose="onDialogClose"
      v-on:onSave="onSetReceiveDate"
    ></ReceiveDialog>
    <KontainerBoxDialog
      :show="showDialog2"
      :action="action2"
      :item="dialogItem2"
      :container_items="container_items"
      :default_customer_box_code="customer_box_code"
      :new_commodity="new_commodity"
      :new_customs_type="new_customs_type"
      :new_deliver_type="new_deliver_type"
      :new_supplier="new_supplier"
      :new_customer="new_customer"
      :commodity_image="dialogImage"
      :commodity_images="dialogImages"
      :commodity_image_ids="dialogImageIds"
      v-on:onClose="fetchUpBoxClose"
      v-on:onSave="submitFetchUpBox"
      v-on:onSaveNext="onSaveNextEvent"
      v-on:onCreateCommodity="createCommodity"
      v-on:onGetCustomerDetail="getCustomerDetail"
      v-on:onClearDialog="clearDialog"
      v-on:onCreateSupplier="createSupplier"
      v-on:onCreateCustomer="createCustomer"
      v-on:onCreateCustomsType="createCustomsType"
      v-on:onCreateDeliverType="createDeliverType"
      v-on:getCommodity="getCommodity"
      v-on:updateImages="updateImages"
      v-on:createCommodityImageEvent="createCommodityImageEvent"
      v-on:updateCommodityCover="updateCommodityCover"
      v-on:deleteCommodityImage="deleteCommodityImage"
      v-on:appendCommodity="appendCommodity"
      v-on:removeRelation="removeRelation"
    ></KontainerBoxDialog>
    <KontainerBoxSplitDialog
      :show="showDialog3"
      :origin_data="origin_data"
      v-on:onSave="saveSplitItem"
      v-on:onClose="onDialogClose3"
    ></KontainerBoxSplitDialog>
    <v-row>
      <v-col>
        <v-btn
          icon
          class="hidden-xs-only"
          @click="
            $router.push({
              name: 'ReceiveList',
              params: {}
            })
          "
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <div class="mb-2" style="">
          {{ $t("container-box.selected") }}{{ selected.length }}
        </div>
      </v-col>
      <v-col class="text-right" style="flex-grow: 0">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="setReceiveDate"
        >
          <v-icon>mdi-calendar</v-icon>
          {{ $t("container-box.set-receive-date") }}
        </v-btn>
      </v-col>
      <v-col
        class="text-right"
        style="flex-grow: 0"
      >
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="notifyCustomers"
        >
          <v-icon>mdi-account-alert</v-icon>
          {{ $t("container-box.notify") }}
        </v-btn>
      </v-col>
      <v-col
        class="text-right"
        style="flex-grow: 0"
      >
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="fetchUpBox"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t("補櫃箱") }}
        </v-btn>
      </v-col>
      <v-col 
        class="text-right" 
        style="flex-grow: 0"
      >
        <v-btn
          color="primary"
          dark
          class="mb-2"
          @click="exportReceiptSheets()"
        >
          <v-icon>mdi-excel</v-icon>
          {{ $t("export") }}{{ $t("receipt_sheet") }}
        </v-btn>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container.receive') + '--' + container_name"
      class="px-5 py-3"
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
        show-select
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100, 200]"
          />
        </template>
        <template v-slot:item.sort_code="{ item }">
          {{ item.code }}
        </template>
        <template v-slot:item.end_box="{ item }">
          {{ get_end_box(item) }}
        </template>
        <template v-slot:item.commodity_name="{ item }">
          <template v-for="data in item.relation">
            <div>{{ data.commodity.name ? data.commodity.name : '&nbsp;' }}</div>
          </template>
        </template>
        <template v-slot:item.commodity_number="{ item }">
          <template v-for="data in item.relation">
            <div>{{ data.commodity.commodity_number }}</div>
          </template>
        </template>
        <template v-slot:item.customs_type="{ item }">
          <template v-for="data in item.relation">
            <div>{{ get_customs_type_name(data) }}</div>
          </template>
        </template>
        <template v-slot:item.supplier="{ item }">
          {{ get_supplier_name(item) }}
        </template>
        <template v-slot:item.customer__id="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.commodity_total_amount="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity_amount }}</div>
            </template>
          </template>
        <template v-slot:item.total_amount="{ item }">
          {{ get_total_amount(item) }}
        </template>
        <template v-slot:item.total_gross_weight="{ item }">
          {{ get_total_gross_weight(item) }}
        </template>
        <template v-slot:item.receive_time="{ item }">
          {{ get_receive_time(item) | Translate }}
        </template>
        <template v-slot:item.receive_status="{ item }">
          {{ get_receive_status(item) | Translate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                :disabled="item.status!==4"
                @click="resetReceiveDate(item)"
              >mdi-minus-circle-outline</v-icon>
            </template>
            <span>{{ $t("container-box.cancel") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                :disabled="item.box_amount==1"
                @click="splitItem(item)"
              >mdi-content-cut</v-icon>
            </template>
            <span>{{ $t("container-box.split") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="updateField(item.id,'enable_invoice', !item.enable_invoice)"
              >{{ item.enable_invoice ? 'mdi-currency-usd' : 'mdi-currency-usd-off' }}</v-icon>
            </template>
            <span>{{ item.enable_invoice ? $t("container-box.invoice") : $t("container-box.no-invoice") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { DatePicker } from "v-calendar";
import BaseTable from "./BaseTable.vue";
import ReceiveDialog from "../dialogs/ReceiveDialog.vue";
import KontainerBoxDialog from "../dialogs/KontainerBoxDialog";
import { action_items } from "@/definition.js";
import moment from "moment";
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import KontainerBoxSplitDialog from "../dialogs/KontainerBoxSplitDialog";
export default {
  data: (vm) => ({
    url: "/container/container_box/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.code"),
        value: "sort_code",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.start_box"),
        value: "start_box",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.end_box"),
        value: "end_box",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "commodity_name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "commodity_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.customs-type"),
        value: "customs_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_amount"),
        value: "total_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer__id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.receive-date"),
        value: "receive_time",
      },
      {
        sortable: true,
        text: vm.$i18n.t("warehouse.warehouse"),
        value: "warehouse.name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container-box.receive-status"),
        value: "receive_status",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container-box.cancel"),
        value: "actions",
      },
    ],
    options: {
      itemsPerPage: 200
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100, 200]
    },
    container: null,
    container_name: null,
    selected: [],
    warehouse_items: [],
    stuffing_date: null,
    // fetcu up box
    action_items: action_items,
    defaultItem2: {
      start_box: 1,
      payment_type: 1,
    },
    showDialog2: false,
    action2: action_items.view,
    dialogItem2: {},
    container_items: [],
    customer_box_code: "",
    new_commodity: {},
    new_customs_type: {},
    new_deliver_type: {},
    new_supplier: {},
    new_customer: {},
    dialogImage: null,
    dialogImages: [],
    dialogImageIds: [],
    showDialog3: false,
    defaultItem3: {},
    origin_data: {},
  }),
  components: {
    DatePicker,
    ReceiveDialog,
    KontainerBoxDialog,
    CommonAlertDialog,
    KontainerBoxSplitDialog,
  },
  mixins: [BaseTable],
  methods: {
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : "";
    },
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    get_customs_type_name(item) {
      return item.customs_type
        ? this.getHanText(item.customs_type, "name")
        : "";
    },
    get_total_amount(item) {
      let sum = 0
      item.relation.forEach(element => {
        sum += element.commodity_amount;
      });
      return sum * item.box_amount;
    },
    get_total_gross_weight(item) {
      return item.gross_weight * item.box_amount;
    },
    get_receive_time(item) {
      return item.receive_time
        ? moment(item.receive_time).utc(true).format("YYYY-MM-DD HH:mm")
        : "";
    },
    get_receive_status(item) {
      switch (item.status) {
        case 3:
          return "container-box.not-setted"
        case 4:
          return "container-box.setted"
        case 5:
          return "container-box.notified"
      }
    },
    setReceiveDate() {
      var boxes = [];
      if (this.selected.length > 0) {
        for (const [key, box] of Object.entries(this.selected)) {
          boxes.push(box.id);
        }
        this.dialogItem = this._.cloneDeep(this.defaultItem);
        this.dialogItem.boxes = boxes;
        const now = new Date()
        this.dialogItem.receive_time = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          9,
          0,
        )
        this.dialogItem.warehouse = this.warehouse_items[0].value
        this.action = this.action_items.create;
        this.showDialog = true;
      } else {
        alert(this.$i18n.t("alert.selected-box-empty"));
      }
    },
    onSetReceiveDate(item) {
      console.log("onSetReceiveDate", item);
      this.postApi(item, "/container/container_box/setReceiveDate/");
      this.showDialog = false;
    },
    onDialogClose(item) {
      this.showDialog = false;
    },
    notifyCustomers() {
      console.log("notifyCustomers", this.container);
      this.postApi([], "/container/container/"+this.container+"/notifyReceive/");
    },
    exportReceiptSheets() {
      const year = this.stuffing_date.split('-')[0]
      const month = this.stuffing_date.split('-')[1]
      const day = this.stuffing_date.split('-')[2]
      const stuffing_date = year + "年" + month + "月" + day + "日"
      const url = "/container/container/" + this.container + "/getReceiptSheets/";
      this.getFileApi(url, "領貨單_"+stuffing_date);
    },
    getWarehouseDatas() {
      this.getApi("/container/warehouse/", this.setWarehouseItems,false,{},{status: this.container_status.enable},{});
    },
    setWarehouseItems(data) {
      this.setListItems(data.results, this.warehouse_items, "name");
    },
    resetReceiveDate(item){
      this.postApi({}, "/container/container_box/"+item.id+"/resetReceiveDate/");
    },
    // fetch up box
    fetchUpBox(){
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
      this.action2 = this.action_items.create;
      this.showDialog2 = true;
    },
    fetchUpBoxClose(){
      this.showDialog2 = false;
      this.customer_box_code = ""
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
      this.dialogImage = null
    },
    submitFetchUpBox(item){
      item.container = this.container
      console.log(item)
      this.showDialog2 = false;
      this.postApi(item,"/container/container_box/");
      this.action2 = this.action_items.view;
      this.dialogItem2 = this._.cloneDeep(this.defaultItem2);
    },
    onSaveNextEvent(data) {
      this.submitFetchUpBox(data);
      this.defaultItem2 = {
        supplier: data.supplier,
        customer: data.customer,
        code: data.code,
        start_box: data.end_box + 1,
      };
      this.fetchUpBox();
    },
    createCommodity(data) {
      if ('image_binary' in data) {
        this.postCommodityImageApi(data)
      } else {
        this.postApi(data, "/commodity/commodity/", this.setNewCommodity);
      }
    },
    postCommodityImageApi(data) {
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/document/document/multi_create/";
      const formData = new FormData();
      data['image_binary'].forEach(image=>{
        formData.append("uploaded_images", image);  
      })
      // formData.append("file", data['image_binary']);
      // formData.append("name", data['image_binary']['name']);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "JWT " + this.token
        }
      };
      this.axios
        .post(url, formData, config)
        .then(function(response) {
          // data['image'] = response.data.id
          var images = response.data.images
          data['image'] = images[0]
          data['extra'] = JSON.stringify({'images':images})
          console.log()
          currentObj.postApi(data, "/commodity/commodity/", currentObj.setNewCommodity);
        })
        .catch(function(error) {
          console.log(error.response);
          if (currentObj.allowAlert) {
            currentObj.alertItem = error.response;
            currentObj.showAlert = true;
          }
          currentObj.loading = false;
        });
    },
    setNewCommodity(action, responseData) {
      this.new_commodity = responseData
    },
    getCustomerDetail(id){
      this.getApi("/customer/customer/" + id + "/", this.setCustomerDetail);
    },
    setCustomerDetail(data){
      this.customer_box_code = data.box_code
    },
    clearDialog(){
      this.new_commodity = null
    },
    createSupplier(data){
      this.postApi(data, "/supplier/supplier/", this.setNewSupplier);
    },
    setNewSupplier(action, responseData) {
      this.new_supplier = responseData
    },
    createCustomer(data){
      this.postApi(data, "/customer/customer/", this.setNewCustomer);
    },
    setNewCustomer(action, responseData) {
      this.new_customer = responseData
    },
    createCustomsType(data){
      this.postApi(data, "/commodity/customs-type/", this.setNewCustomsType);
    },
    setNewCustomsType(action, responseData) {
      this.new_customs_type = responseData
    },
    createDeliverType(data){
      this.postApi(data, "/commodity/deliver-type/", this.setNewDeliverType);
    },
    setNewDeliverType(action, responseData) {
      this.new_deliver_type = responseData
    },
    getCommodity(id){
      console.log('getCommodity',id)
      this.getApi("/commodity/commodity/"+id+"/",this.setDialogImage)
    },
    setDialogImage(data){
      this.dialogImage = data.image
      this.dialogImageIds = data.images
    },
    updateImages(image_ids){
      var params = {'id__in':image_ids}
      this.getApi("/document/document/",this.setAllImages,false,params,{},{},)
    },
    setAllImages(data){
      this.dialogImages = []
      data.results.forEach(img=>{
        this.dialogImages.push({
          id: img.id,
          title: img.name,
          src: img.file
        })
      })
    },
    createCommodityImageEvent(commodity_id,image_binary){
      const data = {}
      data['commodity'] = commodity_id
      data['image_binary'] = image_binary
      this.imageCreateHandler(data,this.action_items.create)
    },
    updateCommodityCover(commodity_id,image_id){
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/changeCover/",this.refreshImage)
    },
    deleteCommodityImage(commodity_id,image_id){
      this.postApi({image_id:image_id},"/commodity/commodity/"+commodity_id+"/deleteImage/",this.refreshImage)
    },
    refreshImage(action, data){
      this.dialogImage = data.image
      this.dialogItem.cover = data.image ? data.image.id : null
      this.dialogImageIds = data.images
    },
    appendCommodity(container_box_id,commodity_id,commodity_amount){
      this.postApi({
        container_box_id: container_box_id,
        commodity_id: commodity_id,
        commodity_amount: commodity_amount
      },"/container/container_box_relation/addRelation/",this.updateDialogItem)
    },
    removeRelation(relationId) {
      this.postApi({},"/container/container_box_relation/"+relationId+"/removeRelation/",this.updateDialogItem)
    },
    updateDialogItem(action, data){
      this.editItem(data)
      this.getApi();
    },
    splitItem(item){
      this.showDialog3 = true;
      this.origin_data  = item
    },
    saveSplitItem(box, splits){
      console.log('saveSplitItem',box,splits)
      this.showDialog3 = false;
      this.postApi({splits:splits}, "/container/container_box/"+ box +"/splitContainerBox/")
    },
    onDialogClose3(){
      this.showDialog3 = false
    },
  },
  watch: {
    options: {
      handler() {
        this.getWarehouseDatas()
      }
    }
  },
  mounted() {
    this.container = this.$route.query.container;
    this.stuffing_date = this.$route.query.stuffing;
    this.getParams = {
      container: this.container,
    };
    this.container_name = this.$route.query.name;
    this.options = {
      sortBy: ["sort_code","customer__id","start_box"],
      sortDesc: [true,false,true],
    };
  },
};
</script>
