var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('ReceiveDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem,"warehouse_items":_vm.warehouse_items},on:{"onClose":_vm.onDialogClose,"onSave":_vm.onSetReceiveDate}}),_c('KontainerBoxDialog',{attrs:{"show":_vm.showDialog2,"action":_vm.action2,"item":_vm.dialogItem2,"container_items":_vm.container_items,"default_customer_box_code":_vm.customer_box_code,"new_commodity":_vm.new_commodity,"new_customs_type":_vm.new_customs_type,"new_deliver_type":_vm.new_deliver_type,"new_supplier":_vm.new_supplier,"new_customer":_vm.new_customer,"commodity_image":_vm.dialogImage,"commodity_images":_vm.dialogImages,"commodity_image_ids":_vm.dialogImageIds},on:{"onClose":_vm.fetchUpBoxClose,"onSave":_vm.submitFetchUpBox,"onSaveNext":_vm.onSaveNextEvent,"onCreateCommodity":_vm.createCommodity,"onGetCustomerDetail":_vm.getCustomerDetail,"onClearDialog":_vm.clearDialog,"onCreateSupplier":_vm.createSupplier,"onCreateCustomer":_vm.createCustomer,"onCreateCustomsType":_vm.createCustomsType,"onCreateDeliverType":_vm.createDeliverType,"getCommodity":_vm.getCommodity,"updateImages":_vm.updateImages,"createCommodityImageEvent":_vm.createCommodityImageEvent,"updateCommodityCover":_vm.updateCommodityCover,"deleteCommodityImage":_vm.deleteCommodityImage,"appendCommodity":_vm.appendCommodity,"removeRelation":_vm.removeRelation}}),_c('KontainerBoxSplitDialog',{attrs:{"show":_vm.showDialog3,"origin_data":_vm.origin_data},on:{"onSave":_vm.saveSplitItem,"onClose":_vm.onDialogClose3}}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
            name: 'ReceiveList',
            params: {}
          })}}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1)],1),_c('v-col',{staticClass:"text-right"},[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("container-box.selected"))+_vm._s(_vm.selected.length)+" ")])]),_c('v-col',{staticClass:"text-right",staticStyle:{"flex-grow":"0"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.setReceiveDate}},[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm.$t("container-box.set-receive-date"))+" ")],1)],1),_c('v-col',{staticClass:"text-right",staticStyle:{"flex-grow":"0"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.notifyCustomers}},[_c('v-icon',[_vm._v("mdi-account-alert")]),_vm._v(" "+_vm._s(_vm.$t("container-box.notify"))+" ")],1)],1),_c('v-col',{staticClass:"text-right",staticStyle:{"flex-grow":"0"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.fetchUpBox}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("補櫃箱"))+" ")],1)],1),_c('v-col',{staticClass:"text-right",staticStyle:{"flex-grow":"0"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.exportReceiptSheets()}}},[_c('v-icon',[_vm._v("mdi-excel")]),_vm._v(" "+_vm._s(_vm.$t("export"))+_vm._s(_vm.$t("receipt_sheet"))+" ")],1)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container.receive') + '--' + _vm.container_name}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":"","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
          var pagination = ref.pagination;
          var options = ref.options;
          var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100, 200]},on:{"update:options":updateOptions}})]}},{key:"item.sort_code",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.end_box",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_end_box(item))+" ")]}},{key:"item.commodity_name",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity.name ? data.commodity.name : ' '))])]})]}},{key:"item.commodity_number",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity.commodity_number))])]})]}},{key:"item.customs_type",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(_vm.get_customs_type_name(data)))])]})]}},{key:"item.supplier",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_supplier_name(item))+" ")]}},{key:"item.customer__id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.commodity_total_amount",fn:function(ref){
          var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity_amount))])]})]}},{key:"item.total_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_total_amount(item))+" ")]}},{key:"item.total_gross_weight",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_total_gross_weight(item))+" ")]}},{key:"item.receive_time",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm.get_receive_time(item)))+" ")]}},{key:"item.receive_status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm.get_receive_status(item)))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":item.status!==4},on:{"click":function($event){return _vm.resetReceiveDate(item)}}},on),[_vm._v("mdi-minus-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container-box.cancel")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":item.box_amount==1},on:{"click":function($event){return _vm.splitItem(item)}}},on),[_vm._v("mdi-content-cut")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container-box.split")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.updateField(item.id,'enable_invoice', !item.enable_invoice)}}},on),[_vm._v(_vm._s(item.enable_invoice ? 'mdi-currency-usd' : 'mdi-currency-usd-off'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.enable_invoice ? _vm.$t("container-box.invoice") : _vm.$t("container-box.no-invoice")))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }